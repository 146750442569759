<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col>
                    <v-progress-linear :active="loading" indeterminate></v-progress-linear>

                    <v-skeleton-loader v-if="loading_skeleton"
                                       v-bind="attrs"
                                       type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-card v-if="dialogDetails">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name !== 'xs' ? 'text-right' : 'pb-0'">
                            <v-col cols="12" class="py-1"><b> {{ $t('number_order')}}: </b>{{ detailsItems.number ? detailsItems.number :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('on_date_order')}}: </b>{{ detailsItems.on_date ?  $moment(detailsItems.on_date).format('DD.MM.YYYY') :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('on_time_order')}}: </b>{{ detailsItems.on_time ? detailsItems.on_time :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('order_type')}}: </b> {{ detailsItems.order_type.name ? detailsItems.order_type.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('important')}}: </b> {{ ( detailsItems.important ? (( detailsItems.important == 1 ) ? $t('yes'): $t('no')) :  $t('no') )  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('company')}}: </b> {{ detailsItems.company ? detailsItems.company :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('class_order')}}: </b> {{ detailsItems.class ? detailsItems.class :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('summa')}}: </b> {{ detailsItems.summa ? detailsItems.summa :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('nature_of_cargo')}}: </b> {{ detailsItems.nature_of_cargo ? detailsItems.nature_of_cargo :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('contract')}}: </b> {{ detailsItems.contract ? detailsItems.contract :  $t('no_data')  }}</v-col>
                        </v-col>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
                            <v-col cols="12" class="py-1"><b>{{ $t('state')}}: </b>{{ detailsItems.state ? detailsItems.state :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('fence_address')}}:</b> {{ detailsItems.address.name ? detailsItems.address.name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('payment_order')}}: </b>{{ detailsItems.payer ? detailsItems.payer :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('courier')}}: </b> {{ detailsItems.courier ? detailsItems.courier :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('is_cash')}}: </b> {{ ( detailsItems.is_cash ? (( detailsItems.is_cash == 1 ) ? $t('yes'): $t('no')) :  $t('no') )  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('reason_text')}}: </b> {{ detailsItems.reason_text ? detailsItems.reason_text :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('description_order')}}: </b> {{ detailsItems.description ? detailsItems.description :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('sender')}}: </b> {{ detailsItems.sender_name ? detailsItems.sender_name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('account_client_name')}}: </b> {{ detailsItems.client_name ? detailsItems.client_name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('phone')}}: </b> {{ detailsItems.phone ? detailsItems.phone :  $t('no_data')  }}</v-col>
                        </v-col>
                    </v-row>
                    <v-row v-if="detailsItems.state_code === 3 ? true : false">
                        <v-col>
                            <v-btn
                                @click="showScan()"
                                width="100%"
                                color="primary"
                            >
                                Сканировать накладные
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row class="p-2 m-2">
                        <v-col cols="12" sm="6" v-for="(invoice, index) in detailsItems.invoices" :key="index" >
                            <v-hover
                                v-slot="{ hover }"
                                open-delay="200"
                            >
                                <v-card
                                    :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }"
                                    :color="invoice.color"
                                >
                                    <v-card-text>
                                        <p class="py-0 my-0"><b>{{ $t('number_invoice') }}:</b> {{ invoice.number }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('name_recipient') }}:</b> {{ invoice.name }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('phone_recipient') }}:</b> {{ invoice.phone }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('address_recipient') }}:</b> {{ invoice.address }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('weight') }}:</b> {{ invoice.weight }} {{ $t('kg') }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('date') }}:</b>  {{  invoice.date }}</p>
                                        <v-col  cols="12" class="py-1 text-right">
                                            <template  class="text-right">
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="mr-3" @click="invoiceEdit(invoice.uuid)" :title="$t('edit')"
                                                        >
                                                            mdi-square-edit-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>{{$t('edit')}}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-col>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-row v-if="detailsItems.state_code === 3 ? true : false">
                        <v-col>
                            <v-btn
                                width="100%"
                                color="primary"
                                @click="takenInvoices()"
                                :disabled="loading"
                                :loading="loading"
                            >
                                Накладные получил(а)
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-dialog v-if="dialogScan" v-model="dialogScan" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Сканировать накладные</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click="cancelScan()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" style="text-align: center">
                                <StreamBarcodeReader class="align-center" ref="scanner" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Отсканированные накладные:</v-list-item-title>
                                        <v-list-item-subtitle v-for="(item, index) in invoiceItems" :key="index">{{ index + 1 }}) <b>{{ item }}</b></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn v-if="invoiceItems.length > 0 ? true: false"
                                       width="100%"
                                       color="primary"
                                       @click="cancelScan()"
                                >
                                    Завершить сканирование
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </v-container>
</template>
<script>



    import {mapGetters} from "vuex";
    import { StreamBarcodeReader } from "vue-barcode-reader";


    export default {
        name: 'RoutersShow',
        inject: ['forceRerender'],
        components: {
            StreamBarcodeReader,
        },
        data() {
            return {
                loading: false,
                progress: 0,
                loading_skeleton: false,
                detailsItems: null,
                invoiceItems: [],
                dialogDetails: false,
                dialogScan: false,
                attrs: {
                    class: 'mb-3',
                    boilerplate: true,
                    elevation: 2,
                },

            }
        },
        computed: {
            ...mapGetters(['lang']),

        },
        async mounted() {
            this.loading_skeleton = true
            await this.getOrder(this.$route.params.uuid)
            this.loading_skeleton = false
        },
        beforeUnmount() {
           if(typeof (this.$refs.scanner) !== "undefined")
           {
               this.$refs.scanner.codeReader.reset();
           }
        },
        methods: {
            onLoaded(){
                console.log("loaded");
            },
            onDecode(text){
                let itm = null
                itm = this.detailsItems.invoices.find(el => el.number === text)
                if(typeof (itm) !== "undefined" && typeof (itm.color) !== "undefined"){
                    let myIndex = this.detailsItems.invoices.indexOf(itm);
                    this.detailsItems.invoices[myIndex].color = 'green'

                    let item = null
                    item = this.invoiceItems.find(el => el === text)
                    if(typeof (item) === "undefined"){
                        this.invoiceItems.push(text)
                        this.invoiceItems.reverse()
                    }
                }
                else {
                    this.$toastr.error(this.$t('invoice_was_not_found_in_application'))
                }




            },
            cancelScan() {
                if(typeof (this.$refs.scanner) !== "undefined")
                {
                    this.$refs.scanner.codeReader.reset();
                }
                this.dialogScan = false;
            },
            async getOrder(id) {
                var _this = this
                this.loading = true;
                let params = {}

                await this.$http
                    .get(`courier/order/${id}`)
                    .then(res => {
                        this.detailsItems = res.body.data
                        this.dialogDetails = true
                    })
                    .catch(err => {
                        this.detailsItems = []
                        this.dialogDetails = false
                        this.$toastr.error(this.$t('failed_to_get_list_orders'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async takenInvoices(){
                let count = 0,
                    count_invoices = 0,
                    cancelInvoices = [];
                if(this.detailsItems !== 'undefined' && this.detailsItems.invoices !== 'undefined' && this.detailsItems !== null && this.detailsItems.invoices !== null)
                {
                    this.detailsItems.invoices.forEach(function(item){
                        if(item.color === 'green'){
                            count = count + 1
                        }
                        else{
                            cancelInvoices.push(item.uuid)
                        }
                    })
                    count_invoices = this.detailsItems.invoices.length;
                }
                if(count !== count_invoices){
                    if (confirm(this.$t('are_you_sure_you_have_received_all_invoices'))) {
                        this.invoiceUpdateStatus(cancelInvoices)
                    }
                }
                else{
                    this.invoiceUpdateStatus(cancelInvoices)
                }
            },
            async showScan() {
                this.dialogScan = true;
            },
            invoiceEdit(uuid) {
                this.$router.push({
                    name: 'invoice.edit',
                    params: {
                        id: uuid
                    }
                })
            },
            async invoiceUpdateStatus(items){
                var _this = this
                this.loading = true;
                let id = this.$route.params.uuid;
                var formData = new FormData()
                formData.append('status', 6)
                if (items) {
                    items.forEach(function(item, key){
                        formData.append(`uuid[${key}]`, item)
                    })
                }

                // Add
                await this.$http
                    .post(`courier/order/${id}/status`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('file_was_uploaded'))
                        this.$router.push({
                            name: 'router'
                        })

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_upload_file'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }

                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })



            }

        }
    }
</script>

